import React from 'react';
import styled from 'styled-components';
import facebookIcon from '../../assets/svgs/facebook_white.svg';


const FbIcon = styled(facebookIcon)`
height: 20;
height: 20;
padding: 10px;
`

const FbEvent = styled.div`
display: flex;
flex-grow:1;
align-items: center;
flex-direction:column;
`

const FacebookEvent = (props) => (
    <>
        <FbEvent as="a" href={props.eventLink} target="_blank" >
            <FbIcon />
        </FbEvent>
    </>
)

export default FacebookEvent;
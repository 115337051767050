import React from 'react';
import styled from 'styled-components';


const Date = styled.div`
    display: flex;
    flex-grow:1;
    align-items: center;
    flex-direction:column;
`

const EventDate = (props)=>(
    <Date>
        {props.date}
    </Date>
)

export default EventDate;
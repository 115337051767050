import React from 'react';
import styled from 'styled-components';

const Place = styled.div`
    flex-grow:3;
    align-items: center;
`

const EventPlace = (props)=>(
    <Place>{props.description}</Place>
)

export default EventPlace;
import React from 'react';
import styled from 'styled-components';
import FacebookEvent from './FacebookEvent';
import EventDate from './EventDate';
import EventPlace from './EventPlace';


const Item = styled.div`
    display: flex;
    border-bottom: 1px solid white;
    height: 5vh;
    align-items: center;
    padding: 5px;
    justify-content: space-around;
    margin: 20px;
`

const EventItem =(props)=>(
    <Item>
        <EventDate date={props.date} />
        <EventPlace description={props.place}/>
        <FacebookEvent eventLink={props.eventLink} />
    </Item>
)

export default EventItem;
import React from 'react';
import styled from 'styled-components';
import Menu from '../components/Menu/menu';
import Footer from '../components/Footer/footer';
import Layout from "../components/Layout/layout"
import image from '../assets/images/background.jpg'
import EventList from '../components/Events/EventList';
import NewsletterSubscription from '../components/Newsletter/NewsletterSubscription';

const EventsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background-image: ${({ image }) => `url(${image})`};
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 100vh;
`;

const MenuWrapper = styled.div`
    display:flex;
    flex-direction: column;
    height: 10vh;
`

const ContentWrapper = styled.div`
    display:flex;    
    justify-content:center;
    align-items:center;    
    height: 80vh;
    width: 100%;
`

const Content = styled.div`
    display: flex;
    width: 90%;
    height: 70vh;
    overflow:auto;
    padding: 30px;
    background-color: black;
    opacity:0.8;
    color: white;
    flex-direction: column;
    justify-content: space-between;
`

const H1 = styled.h1`
    font-size: 2.0em;
`

const EventsPage = () => (
    <Layout>
        <EventsWrapper image={image}>
            <MenuWrapper>
                <Menu showHome />
            </MenuWrapper>
            <ContentWrapper>
                <Content>
                    <H1>
                        nadchodzace wydarzenia
                    </H1>
                    <EventList
                    // events={[
                    //     { date: "10.10.2020", place: "test", eventLink: "https://google.com" },
                    //     { date: "10.10.2020", place: "test", eventLink: "https://google.com" },
                    //     { date: "10.10.2020", place: "test", eventLink: "https://google.com" },
                    // ]}    
                    />
                    <NewsletterSubscription text={"nie przegap nadchodzących wydarzeń i "} linkText={"dołącz do newslettera!"} showIcons={true}/>
                
                </Content>

            </ContentWrapper>
            <Footer />
        </EventsWrapper>
    </Layout>
)

export default EventsPage;
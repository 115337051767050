import React from 'react';
import styled from 'styled-components';
import EventItem from './EventItem';

const EventListWrapper = styled.div`
    display: flex;
    flex-direction: column;    
    height: 40vh;
    overflow: auto;
`

const EmptyList = styled.div`
    display: flex;
    justify-content: center;
justify-self: center;
align-self: center;
    /* font-size: 1.5em; */
`

const EventList = (props) => (
    <EventListWrapper>

        {!props.events || props.events.length === 0 ? <EmptyList>
            brak nadchodzacych wydarzen
        </EmptyList> : null }

        {props.events && props.events.map(x=>(
            <EventItem date={x.date} place={x.place} eventLink={x.eventLink}/>
        ))}
    </EventListWrapper>
)

export default EventList;